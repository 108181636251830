<template>
  <div class="modal" style="z-index: 9999 !important">
    <div class="modal__header">
      <div class="modal__header__title">
        <span>Envio teste </span>
        <br />
        <small>Insira a informação abaixo para prosseguir</small>
      </div>
      <div class="action">
        <v-icon @click="$emit('close')">mdi-close</v-icon>
      </div>
    </div>

    <div class="modal__body">
      <v-form ref="form">
        <z-input
          v-model="phoneNumber"
          :rules="[(v) => !!v || 'Campo obrigatório']"
          :mask="'+## (##) #####-####'"
          :hideDetails="true"
          label="Número"
          type="text"
        />
      </v-form>
    </div>

    <div class="modal--actions mt-3">
      <z-btn small text="Cancelar" @click="$emit('close')" />
      <z-btn small primary text="Concluir" :is-loading="isLoading" @click="submit" />
    </div>
  </div>
</template>

<script>
import ApiClient from "@/services/ApiClient";
import moment from "moment";
export default {
  props: {
    templateToSaveProp: { type: Object, default: null },
    messageContent: { type: String, default: "" },
    defaultValues: { type: Object, default: null },
    firstData: { type: Array, default: null },
    databaseFields: { type: Object, default: null },
    choosenConnectionProp: { type: Object, edfault: null },
  },
  data() {
    return {
      isLoading: false,
      phoneNumber: "",
      http: new ApiClient(),
    };
  },
  methods: {
    transformString(text, data) {
      console.log(data);

      if (data) {
        const keys = Object.keys(data);
        for (let i = 0; i < keys.length; i++) {
          if (data[keys[i]])
            text = text.replaceAll(new RegExp(`\\$${keys[i]}`, "ig"), data[keys[i]]);
        }
      } else if (this.firstData && this.firstData.length > 0) {
        if (this.firstData && this.firstData.length > 0) {
          for (let j = 0; j < this.firstData.length; j++) {
            const keys = Object.keys(this.firstData[j]);

            for (let i = 0; i < keys.length; i++) {
              let fieldConfig = this.databaseFields[keys[i]];
              let fieldValue = "";

              if (!this.firstData[j][keys[i]] || !fieldConfig) continue;

              if (fieldConfig.type === "decimal") {
                fieldValue = parseFloat(this.firstData[j][keys[i]])
                  .toFixed(2)
                  .replace(".", ",");
              } else if (fieldConfig.type === "date") {
                fieldValue = moment(this.firstData[j][keys[i]]).format("DD/MM/yyyy");
              } else if (fieldConfig.type === "datetime") {
                fieldValue = moment(this.firstData[j][keys[i]]).format(
                  "DD/MM/yyyy hh:mm"
                );
              } else {
                fieldValue = this.firstData[j][keys[i]];
              }

              //eslint-disable-next-line
              let patternString = "\\$" + keys[i] + "(?![a-zA-Z])";
              let pattern = new RegExp(patternString, "gi");

              if (fieldValue) text = text.replace(pattern, fieldValue);
            }
          }
        }
      }

      if (this.defaultValues) {
        const keys = Object.keys(this.defaultValues);

        console.log(keys);

        for (let i = 0; i < keys.length; i++) {
          let patternString = "\\$" + keys[i] + "(?![a-zA-Z])";
          let pattern = new RegExp(patternString, "gi");
          text = text.replace(pattern, this.defaultValues[keys[i]]);
        }
      }

      return text;
    },
    getNumbers(string) {
      // Filtrar apenas os dígitos da string usando o método split e join
      let numeros = string.split("").filter((char) => /\d/.test(char));
      let numerosPuros = numeros.join("");
      return numerosPuros;
    },
    async submit() {
      if (this.$refs.form.validate()) {
        let personalizedData = await this.http.get(
          `dbConfiguration/getFirstData/${
            this.choosenConnectionProp.id
          }?phone=${this.phoneNumber.replace(/\D/g, "")}`
        );

        let OBJ = {};
        OBJ.content = { ...this.templateToSaveProp };
        OBJ.content.databaseConfigId = this.choosenConnectionProp?.id;
        OBJ.phoneNumber = this.getNumbers(this.phoneNumber);

        OBJ.content.message = this.transformString(
          this.messageContent,
          personalizedData[0]
        );

        // console.log(this.getNumbers(OBJ.phoneNumber));

        try {
          this.isLoading = true;
          const response = await this.http.post(
            `templatesConfiguration/sendWhatsAppTemplateTest`,
            {
              phoneNumber: OBJ.phoneNumber,
            }
          );

          if (response) {
            this.isLoading = false;
            this.$toast.success("Sucesso!");
            this.$emit("close");
          }
        } catch (error) {
          console.log(error);
          if (error && error.body && error.body.detail)
            this.$toast.error(error.body.detail);
          else this.$toast.error("Erro ao realizar a requisição");
          this.isLoading = false;
          throw error;
        }

        // await this.http
        //   .post(`templatesConfiguration/sendSmsTemplateTest`, {
        //     ...OBJ,
        //   })
        //   .then((resp) => {
        //     console.log(resp);
        //     this.firstData = resp;
        //   })
        //   .catch((err) => {
        //     throw err;
        //   });

        // } else {
        //   this.$toast.warning("Preencha todas as informações necessárias");
        //   this.$emit("onErroInInformations");
        // }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.modal {
  background: #ffffff;
  padding: $z-s-1;
  border-radius: 15px;
  color: $z-black-1;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $z-s-1;

    &__title {
      span {
        font-weight: 600;
        text-transform: uppercase;
        line-height: 15px;
        font-size: 1rem;
      }
    }
  }

  &__body {
    color: #4e4e4e;
    .content {
      strong {
        display: block;
        font-size: 0.85rem;
      }
      &__timer-type {
      }

      &__recurring-info {
        .custom_inpt {
          ::v-deep .v-text-field__slot input {
            text-align: center !important;
          }

          ::v-deep .v-select__selections input {
            text-align: center !important;
            // border: 1px solid red !important;
            padding: 0 !important;
          }

          ::v-deep .v-select__selections .v-select__selection {
            // border: 2px solid green !important;
            text-align: center !important;
            width: 100% !important;
          }
        }
        .repeat-each {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          // flex-wrap: wrap;
          @media (max-width: 650px) {
            flex-wrap: wrap;
          }
        }
      }
    }
    // .schedule {
    //   &__title {
    //     margin-bottom: 0.5rem;
    //     span {
    //       font-size: 1rem;
    //       font-weight: 500;
    //     }
    //   }

    //   &__content {
    //     & > div {
    //       span {
    //         display: block;
    //         font-weight: 400;
    //       }
    //     }

    //     &__repeat,
    //     &__repeat-times {
    //       & > div {
    //         margin-bottom: 0.5rem;
    //       }
    //     }
    //   }
    // }
  }

  &--actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
  }
}
</style>
