<template>
  <div class="modal">
    <div class="modal__header">
      <div class="modal__header__title">
        <span>Botões criados</span>
      </div>
    </div>

    <div class="modal__body">
      <ul>
        <li v-for="info in buttonsListProp" :key="info.id">
          <div class="title">
            <strong> {{ info.text }}</strong>
          </div>
          <div class="actions">
            <v-icon color="#fff" class="mr-2" @click="openConfirmationModal(info)"
              >$delete_forever</v-icon
            >
            <v-icon color="#fff" @click="editCurrentButton(info)">$edit_rounded</v-icon>
          </div>
        </li>
      </ul>
    </div>

    <div class="modal__footer d-flex justify-end">
      <z-btn text="Fechar" color="primary" class="mr-2" @click="$emit('close')" />
      <!-- <z-btn text="Criar" primary color="primary" @click="createNewButton" /> -->
    </div>

    <z-card-modal v-model="confirmDeleteButtonDialog" width="400" title="">
      <template #content>
        <div class="modal__footer d-flex justify-center align-center flex-column py-2">
          <strong> Tem certeza que deseja apagar esse botão? </strong>

          <div class="actions mt-6">
            <z-btn
              text="Cancelar"
              color="primary"
              class="mr-2"
              @click="
                confirmDeleteButtonDialog = false;
                currentSelectedInfo = null;
              "
            />
            <z-btn
              text="Confirmar"
              primary
              color="primary"
              @click="deleteCurrentButton"
            />
          </div>
        </div>
      </template>
    </z-card-modal>
  </div>
</template>

<script>
export default {
  props: {
    buttonsListProp: { type: Array, default: null },
  },
  data() {
    return {
      confirmDeleteButtonDialog: false,
      types: ["PHONE_NUMBER", "URL", "QUICK_REPLY", "COPY_CODE"],
      buttonInfo: { type: "" },
      currentSelectedInfo: null,
      currentSelectedInfoIndex: null,
    };
  },
  methods: {
    editCurrentButton(info) {
      this.$emit("editCurrentButton", info);
      this.$emit("close");
    },
    deleteCurrentButton() {
      this.$emit("deleteCurrentButton", this.currentSelectedInfo);

      this.confirmDeleteButtonDialog = false;
      this.currentSelectedInfo = null;
    },
    openConfirmationModal(info) {
      this.currentSelectedInfo = info;
      this.confirmDeleteButtonDialog = true;
    },
    updateObject() {
      //  apagar as chaves url e phone number dinamicamente
      // dependdendo to tipo de type que for selecionado
      this.buttonInfo.type == "PHONE_NUMBER"
        ? delete this.buttonInfo.url
        : this.buttonInfo.type == "URL"
        ? delete this.buttonInfo.phoneNumber
        : delete this.buttonInfo.phoneNumber,
        delete this.buttonInfo.url;
    },
    createNewButton() {
      if (this.$refs.form.validate()) {
        this.$emit("close", this.buttonInfo);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.modal {
  &__header {
    &__title {
      span {
        font-weight: 600;
        text-transform: uppercase;
        line-height: 15px;
        font-size: 1rem;
      }
    }
  }

  &__body {
    margin: $z-s-1 0;
    display: flex;
    flex-direction: column;
    gap: 0.9rem;

    ul {
      height: 60vh;
      overflow-y: auto;
      padding: 0 0.5rem 1rem;
      @include trackScrollBar;

      li {
        list-style: none;
        padding: 0.8rem 1rem;
        border-radius: 10px;
        background: #2b81d6;
        color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:not(:last-child) {
          margin-bottom: 1rem;
        }
      }
    }
  }
}
</style>
