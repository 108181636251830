var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "modal" },
    [
      _vm._m(0),
      _c("div", { staticClass: "modal__body" }, [
        _c(
          "ul",
          _vm._l(_vm.buttonsListProp, function (info) {
            return _c("li", { key: info.id }, [
              _c("div", { staticClass: "title" }, [
                _c("strong", [_vm._v(" " + _vm._s(info.text))]),
              ]),
              _c(
                "div",
                { staticClass: "actions" },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "mr-2",
                      attrs: { color: "#fff" },
                      on: {
                        click: function ($event) {
                          return _vm.openConfirmationModal(info)
                        },
                      },
                    },
                    [_vm._v("$delete_forever")]
                  ),
                  _c(
                    "v-icon",
                    {
                      attrs: { color: "#fff" },
                      on: {
                        click: function ($event) {
                          return _vm.editCurrentButton(info)
                        },
                      },
                    },
                    [_vm._v("$edit_rounded")]
                  ),
                ],
                1
              ),
            ])
          }),
          0
        ),
      ]),
      _c(
        "div",
        { staticClass: "modal__footer d-flex justify-end" },
        [
          _c("z-btn", {
            staticClass: "mr-2",
            attrs: { text: "Fechar", color: "primary" },
            on: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          }),
        ],
        1
      ),
      _c("z-card-modal", {
        attrs: { width: "400", title: "" },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "modal__footer d-flex justify-center align-center flex-column py-2",
                  },
                  [
                    _c("strong", [
                      _vm._v(" Tem certeza que deseja apagar esse botão? "),
                    ]),
                    _c(
                      "div",
                      { staticClass: "actions mt-6" },
                      [
                        _c("z-btn", {
                          staticClass: "mr-2",
                          attrs: { text: "Cancelar", color: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.confirmDeleteButtonDialog = false
                              _vm.currentSelectedInfo = null
                            },
                          },
                        }),
                        _c("z-btn", {
                          attrs: {
                            text: "Confirmar",
                            primary: "",
                            color: "primary",
                          },
                          on: { click: _vm.deleteCurrentButton },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.confirmDeleteButtonDialog,
          callback: function ($$v) {
            _vm.confirmDeleteButtonDialog = $$v
          },
          expression: "confirmDeleteButtonDialog",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal__header" }, [
      _c("div", { staticClass: "modal__header__title" }, [
        _c("span", [_vm._v("Botões criados")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }